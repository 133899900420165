import React, { useState, useRef, useEffect } from "react";
import Container from "../../remitbee/components/container/Container";
import Typography from "../../remitbee/components/typography/Typography";
import Spacing from "../../remitbee/styles/spacing/Spacing";
import FormFields from "../sendMoneyV2/RecipientDetails/RecipientForm/FormFields/FormFields";
import Button from "../../remitbee/components/button/Button";
import theme from "../../remitbee/theme/Theme";
import Divider from "../../remitbee/components/divider/Divider";
import { setHasPhoneNumber, setPhoneValidated, generateTwoFactorCodeV2 } from '../../redux/auth/actions';
import { setCustomerPhone } from '../../redux/customer/actions';
import {
    sendCustomerPhoneVerificationCode,
    updateCustomer,
    verifyCustomerPhoneVerificationCode,
} from '../../redux/customer/actions';
import { useRouter } from "next/router";
import { setRedirectSignupCookie } from '../../shared/cookie-handler';
import { fetchCountriesFromRates } from '../../redux/system/actions';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { ACTIONS, Analytics } from '../../settings/analytics';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CodeVerificationInput from "../design-system/CodeVerificationInput/CodeVerificationInput";
import { snackbar } from '../../remitbee/components/snackbar/SnackBar';
import Box from "../../remitbee/components/box/Box";
import classes from './SignupWizard.module.scss'
import { useMediaQuery } from "@mui/material";
import { BrowserLogger, BrowserLoggerOperation } from "../../settings/browser-logger-v2";
import { MoEngageTrackEvent } from "../../settings/moengage/moengage-service";
import moengage_events from "../../settings/moengage/moengage-event";

const FORM_CONTACT_INFORMATION = [
    {
        id: 'cus_phone1',
        field_value: 'cus_phone1',
        field_label: 'Phone number',
        is_required: '1',
        section_width: 'FULL',
        placeholder: 'e.g., 002939293',
        withOutDropdown: true,
        field_name: 'ben_phone1',
        error: 'Please enter a valid phone number',
        regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    }
]

const PhoneNumberVerification = ({ props, validationResultHandler, onClickSendCode, signup, phoneNumberData, }) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const { isComplianceVerification, initialValue, isAllowedToEdit, authPreference } = props || {};
    const [isCodeVerification, setIsCodeVerification] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [retryAttempt, setRetryAttempt] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const customer = useSelector((state: any) => state?.customer);
    const business = useSelector((state: any) => state?.business);
    const [phoneNumber, setPhoneNumber] = useState(
        initialValue
            ? initialValue
            : isComplianceVerification
                ? customer
                    ? customer.cus_phone1
                    : ''
                : ''
    );
    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [countDown, setCountDown] = useState(30);
    const [isSkipEnabled, setIsSkipEnabled] = useState(false);
    const [isFromResend, setIsFromResend] = useState(false);
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { errors },
        trigger,
        getValues,
        setError,
        setValue,
        clearErrors
    } = useForm();
    const propsToBePassed = {
        getValues,
        setValue,
        control,
        errors,
        trigger,
        setError,
        clearErrors
    }
    const [submitting, setSubmitting] = useState(false)
    const dispatch = useDispatch();
    const [isSettings, setIsSettings] = useState(false);

    const timeout: React.MutableRefObject<undefined | NodeJS.Timeout> = React.useRef(undefined);

    const router = useRouter()

    useEffect(() => {
        if (countDown === 0) {
            onStop();
        }
    }, [countDown]);

    useEffect(() => {
        return () => {
            onStop();
        }
        if (phoneNumberData) setPhoneNumber(phoneNumberData)
    }, []);

    useEffect(() => {
        if (router.route === '/settings') {
            setIsSettings(true)
            onSubmit({ cus_phone1: phoneNumberData })
        }
    }, [router.query])

    const startTimer = () => {
        timeout.current = setInterval(() => {
            setCountDown((prevState) => prevState - 1);
        }, 1000);
    };

    const onStop = () => {
        if (timeout) clearInterval(timeout.current);
    };

    useEffect(() => {
        if (errorMessage) {
            snackbar.error(errorMessage, 10000);
        }
    }, [errorMessage])

    const onSubmit = async (values: any) => {
        setPhoneNumber(values?.cus_phone1);
        MoEngageTrackEvent(moengage_events.SIGNUP_PHONE_NUMBER_SUBMITTED)
        setErrorMessage(null);
        setCountDown(30);
        if (isFromResend) {
            setRetryAttempt((r) => r + 1);
            setResendLoading(true);
        } else {
            setLoading(true);
        }

        const { cus_email, cus_unique_id } = customer || {};
        const retry_attempt = retryAttempt + 1;
        setRetryAttempt(retry_attempt);

        BrowserLogger.log(`Customer clicked ${retry_attempt > 1 ? 'resend' : 'send'} code`, {
            action_from: 'PhoneNumberVerification',
            operation: BrowserLoggerOperation.SIGNUP_PHONE,
            function_name: 'onSubmit',
            retry_attempt,
            cus_email,
            cus_unique_id,
        });

        const { data, errorMessage, success } = await sendCustomerPhoneVerificationCode(values?.cus_phone1 ? values?.cus_phone1 : customer?.cus_phone1, {
            isFromResend,
            cus_email,
            cus_unique_id,
            retry_attempt,
            is_business: business?.account_type === 'business'
        });
        setLoading(false);
        setResendLoading(false);

        if (success) {
            snackbar.success('Verification code sent successfully', 10000);
            if (data?.sendCustomerPhoneVerificationCodeV3?.cus_phone1 === (values?.cus_phone1) || (customer && data?.sendCustomerPhoneVerificationCodeV3?.cus_phone1 === customer?.cus_phone1)) {
                setIsCodeVerification(true);
                dispatch(setCustomerPhone(data.sendCustomerPhoneVerificationCodeV3?.cus_phone1));
                const retryTimer = data
                    && data?.sendCustomerPhoneVerificationCodeV3
                    && data?.sendCustomerPhoneVerificationCodeV3?.retry_in_seconds
                    && +data?.sendCustomerPhoneVerificationCodeV3?.retry_in_seconds;
                if (typeof retryTimer === 'number' && retryTimer > 0) {
                    setCountDown(retryTimer);
                }
                startTimer();
                if (isFromResend) {
                    setIsSkipEnabled(true);
                }
                if (onClickSendCode && router?.asPath !== '/login?step=phone-verification') onClickSendCode((isSettings && isFromResend) ? values : values?.cus_phone1);
                if (signup === false) {
                    router.query.step = '3'
                }
            } else {
                setErrorMessage('Phone number mismatch. Please try again.');
            }
        }

        if (errorMessage) {
            const waitTime = errorMessage.replace(/[^0-9]/g, '');
            if (waitTime) {
                setCountDown(30);
                startTimer();
            }
            setErrorMessage(errorMessage);

            BrowserLogger.error(errorMessage, {
                action_from: 'PhoneNumberVerification',
                function_name: 'onSubmit',
                operation: BrowserLoggerOperation.SIGNUP_PHONE,
                function: 'onSubmit',
            });
        }
    };
    const onClickVerifyCode = async () => {
        setLoading(true);
        setErrorMessage(null);
        const { cus_unique_id, cus_email } = customer || {};
        const { account_type } = business || {};

        BrowserLogger.log('Customer clicked verify code', {
            action_from: 'PhoneNumberVerification',
            operation: BrowserLoggerOperation.SIGNUP_PHONE,
            function_name: 'onClickVerifyCode',
            cus_unique_id,
        });

        MoEngageTrackEvent(moengage_events.SIGNUP_COMPLETED)

        const { success, data, errorMessage } = await verifyCustomerPhoneVerificationCode(verificationCode, cus_unique_id, customer?.cus_phone1);
        if (success) {
            await setRedirectSignupCookie('')
            dispatch(setHasPhoneNumber(true))
            const cp_session_token = data?.verifyCustomerPhoneVerificationCodeV2 && data?.verifyCustomerPhoneVerificationCodeV2?.cp_session_token;
            dispatch(setPhoneValidated({ isPhoneValidated: true, cp_session_token: cp_session_token }));
            dispatch(setCustomerPhone(phoneNumber));
            // fetch updated country rates
            fetchCountriesFromRates()
            if (validationResultHandler) {
                // Analytics.track(ACTIONS.VERIFICATION_PHONE, {success});
                Analytics.track(ACTIONS.SIGNUP_COMPLETED, { personalInformation: { cus_email, cus_unique_id, cus_phone1: phoneNumber, is_business: account_type === 'business' } });
                validationResultHandler(true)
            } else if (props?.isComplianceVerification) {
                // Will refresh the compliance level page from which this component is being called
                props?.onClosePhoneVerificationDialog && props?.onClosePhoneVerificationDialog(true);
            } else {
                router.push('/dashboard');
            }
        }
        else {
            if (validationResultHandler) validationResultHandler(false);
            setErrorMessage(errorMessage);
            setLoading(false);

            BrowserLogger.log(errorMessage, {
                action_from: 'PhoneNumberVerification',
                function_name: 'onClickVerifyCode',
                operation: BrowserLoggerOperation.SIGNUP_PHONE,
                function: 'onClickVerifyCode',
            });
        }
    };

    return (
        <>
            {
                isCodeVerification || router.query.step === '3' ? <>
                    {!isMobile && <Container style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                        type={isSettings ? 'small' : 'extrasmall'}
                    >
                        <div>
                            <Typography id='enter-your-verification-code' variant='h2' weight='semibold' color='#31517A'>
                                {isSettings ? 'Check your phone for a code' : 'Enter your verification code'}
                            </Typography>
                            {isSettings && <>
                                <Spacing variant='contentToCtaSmall' />
                                <Divider />
                            </>}
                            {isSettings ? <Spacing variant="titleToCard" /> : <Spacing variant='betweenHeaderToTab' />}
                            {isSettings ? <Typography variant="body1" >{`We sent a 6-digit verification code to your phone number ending with ${phoneNumber && phoneNumber.substr(phoneNumber.length - 4)}. Enter the code below to turn off your two-step login. `}</Typography>
                                : <Typography id='enter-code' variant='label2'>
                                    {'Enter the 6-digit code you received by SMS'}
                                </Typography>
                            }
                            <Spacing variant={isSettings ? "headerToContent" : "betweenSectionsSmall"} />
                            <form onSubmit={handleSubmit(onClickVerifyCode)}>
                                <Container className={isSettings ? classes['rb-phonenumber-verification'] : classes['rb-phonenumber-verification-2']}>
                                    <CodeVerificationInput
                                        length={6} onComplete={(code) => setVerificationCode(code)}
                                    />
                                </Container>
                                {
                                    isSettings && <>
                                        <Spacing variant='betweenHeaderToSubheader' />
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography variant="body1">{'Didn’t receive a code?'}</Typography>
                                            <Typography id='resend-code' color={theme.palette.primary.blue} link onClick={() => {
                                                setIsFromResend(true);
                                                onSubmit(phoneNumber);
                                            }}>{"Resend code"}</Typography>
                                        </Box>
                                    </>
                                }
                                <Spacing variant='betweenSections' />
                                <Button id='verify-code' fullWidth disabled={loading || verificationCode?.length !== 6}
                                    loading={loading}>{isSettings ? 'Continue' : "Verify code"}</Button>
                            </form>
                            <Spacing variant='betweenSectionsSmall' />
                            {!isSettings && <Button id='resend-code' variant="text" fullWidth onClick={() => {
                                setIsFromResend(true);
                                onSubmit(phoneNumber);
                            }}>{"Resend code"}</Button>}
                        </div>
                    </Container>}
                    {
                        isMobile &&
                        <Spacing variant='containerVerticalPadding'>
                            <Typography id='enter-your-verification-code' variant='h2' weight='semibold' color='#31517A'>
                                {isSettings ? 'Check your phone for a code' : 'Enter your verification code'}
                            </Typography>
                            {!isSettings && <><Spacing variant='betweenHeaderToSubheader' />
                                <Divider /></>}
                            <Spacing variant='betweenHeaderToTab' />
                            {isSettings ? <Typography variant="body1" >{`We sent a 6-digit verification code to your phone number ending with ${customer?.cus_phone1?.substr(customer?.cus_phone1.length - 4)}. Enter the code below to turn off your two-step login. `}</Typography>
                                : <Typography id='enter-code' variant='label2'>
                                    {'Enter the 6-digit code you received by SMS'}
                                </Typography>
                            }
                            <Spacing variant={isSettings ? "betweenSectionToInputFields" : "betweenSectionsSmall"} />
                            <form onSubmit={handleSubmit(onClickVerifyCode)}>
                                <CodeVerificationInput
                                    length={6} onComplete={(code) => setVerificationCode(code)}
                                />
                                <Spacing variant={isSettings ? 'betweenHeaderToSubheader' : 'betweenSectionsSmall'} />
                                {
                                    isSettings && <>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography variant="body1">{'Didn’t receive a code?'}</Typography>
                                            <Typography color={theme.palette.primary.blue} id='resend-code' link onClick={() => {
                                                setIsFromResend(true);
                                                onSubmit(phoneNumber);
                                            }}>{"Resend code"}</Typography>
                                        </Box>
                                    </>
                                }
                                <Spacing variant='betweenSectionToInputFields' />
                                <Button id='verify-code' fullWidth disabled={loading || verificationCode?.length !== 6}
                                    loading={loading}>{"Verify code"}</Button>
                                {!isSettings && <Button id='resend-code' variant="text" fullWidth onClick={() => {
                                    setIsFromResend(true);
                                    onSubmit(phoneNumber);
                                }}>{"Resend code"}</Button>}
                            </form>
                        </Spacing>
                    }
                </> : (router.query.step === '2' || router.query.step === 'phone-verification') && <>
                    {
                        !isMobile && <Container style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                            type='extrasmall'
                        >
                            <div>
                                <Typography id='verify-your-phone-number' variant='h2' weight='semibold' color='#31517A'>
                                    {'Verify your phone number'}
                                </Typography>
                                <Spacing variant='betweenHeaderToTab' />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormFields
                                        fullWidth
                                        fields={FORM_CONTACT_INFORMATION}
                                        phoneNumberCodeWidth={120}
                                        {...propsToBePassed}
                                    />
                                    <Spacing variant='betweenSectionToInputFields' />
                                    <Button fullWidth id='send-code' type='submit'
                                        loading={loading || submitting}
                                    >Send code</Button>
                                </form>
                            </div>
                        </Container>
                    }
                    {
                        isMobile &&
                        <Spacing variant='containerVerticalPadding' >
                            <div>
                                <Typography id='verify-your-phone-number' variant='h2' weight='semibold' color='#31517A'>
                                    {'Verify your phone number'}
                                </Typography>
                                <Spacing variant='betweenHeaderToSubheader' />
                                <Divider />
                                <Spacing variant='betweenHeaderToTab' />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormFields
                                        fullWidth
                                        fields={FORM_CONTACT_INFORMATION}
                                        phoneNumberCodeWidth={120}
                                        {...propsToBePassed}
                                    />
                                    <Spacing variant='betweenSectionToInputFields' />
                                    <Button fullWidth id='send-code' type='submit'
                                        loading={loading || submitting}
                                    >Send code</Button>
                                </form>
                            </div>
                        </Spacing>
                    }
                </>
            }
        </>
    )
}

PhoneNumberVerification.propTypes = {
    classes: PropTypes.object.isRequired,
};

const VerifyPhoneNumberStyled = (PhoneNumberVerification);

const mapStateToProps = (state) => ({
    customer: state.customer,
    business: state.business,
});
export default connect(mapStateToProps, { updateCustomer, setHasPhoneNumber, setPhoneValidated, setCustomerPhone, fetchCountriesFromRates })(
    VerifyPhoneNumberStyled
);
