import React, { useState, useEffect, useCallback } from "react";
import Box from "../../remitbee/components/box/Box";
import { set, useForm } from "react-hook-form";
import { useRouter } from 'next/router';
import Login from "./loginPage/Login";
import Drawer from "../design-system/drawer/Drawer";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import theme from '../../../src/remitbee/theme/Theme';
import { useMediaQuery } from '@mui/material';
import TopBar from "../../remitbee/components/topBar/TopBar";
import CheckEmail from "./forgotPassword/CheckEmail";
import VerificationCodeScreen from "./forgotPassword/VerificationCodeScreen";
import PhoneNumberVerification from "../signup-v2/phoneNumberVerification";
import { snackbar } from '../../remitbee/components/snackbar/SnackBar';
import { generateTwoFactorCodeV2 } from '../../redux/auth/actions';
import CloseIcon from "../../remitbee/icons/CloseIcon";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import Spacing from "../../remitbee/styles/spacing/Spacing";
import classes from './LoginWizard.module.scss';
import { useSelector } from 'react-redux';

export const steps = [
    {
        key: 'login',
        label: 'login'
    },
    {
        key: 'login-2fa',
        label: 'login-2fa'
    },
    {
        key: 'phone-verification',
        label: 'phone-verification'
    },
    {
        key: 'signup',
        label: 'signup'
    },
    {
        key: 'forgot-password',
        label: 'forgot-password'
    },
    {
        key: 'dashboard',
        label: 'dashboard',
    }, {
        key: 'check-email',
        label: 'check-email',
    }
];

// const isProd = process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'staging';
const isProd = true;

export const LoginWizard = ({ t , isMobileServer}) => {
    const router = useRouter();
    const [resetEmail, setResetEmail] = useState('');
    const [activeStep, setActiveStep] = useState(null);
    const [token, setToken] = useState("");
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [state, setState] = useState({
        errorMessage: null,
        cus_2fa_preference: null,
        cus_unique_id: null,
        recaptchaInvisible: true,
        loginAttemptsCounter: 0,
        forgotPassword: false,
        recoveryPasswordEmailSent: false,
        showPassword: false,
        isCusPhoneValid: true,
        isLegacyVerifyFlow: false,
        passwordUpdated: false,
        loading: false,
    });
    const isMobileClient = useMediaQuery(`(max-width:${theme.breakpoints.lg}px)`);
    const [isMobile, setIsMobile] = useState(isMobileServer);
    const [enableResendingTimer, setEnableResendingTimer] = useState(60);
    const { cus_unique_id: cusUniqId } = useSelector((state:any) => state.customer);
    useEffect(() => {
        setActiveStep(activeStepBySlug());
    }, [state, state.cus_2fa_preference])

    useEffect(() => {
        setIsMobile(isMobileClient);
    }, [isMobileClient])

    const {
        handleSubmit,
        control,
        formState: { errors },
        trigger,
        getValues,
        setError,
        setValue,
        clearErrors
    } = useForm();
    const propsToBePassed = {
        getValues,
        setValue,
        control,
        errors,
        trigger,
        setError,
        clearErrors
    }

    useEffect(() => {
        if (router.query && router.query.step) {
            setActiveStep(activeStepBySlug());
        } else {
            setState({
                errorMessage: null,
                cus_2fa_preference: null,
                cus_unique_id: null,
                recaptchaInvisible: true,
                loginAttemptsCounter: 0,
                forgotPassword: false,
                recoveryPasswordEmailSent: false,
                showPassword: false,
                isCusPhoneValid: true,
                isLegacyVerifyFlow: false,
                passwordUpdated: false,
                loading: false,
            })
            setActiveStep(0)
        }
    }, [router.query.step])

    useEffect(() => {
        handleReCaptchaVerify();
    }, [executeRecaptcha]);

    const fetchNewToken = async () => {
        if (!executeRecaptcha) return;

        setToken("");
        const token = await executeRecaptcha('login');
        setToken(token);
    }

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            // Execute recaptcha not yet available'
            return;
        }
        if (isProd && state && !state.loading && executeRecaptcha) {
            fetchNewToken();
        }
    }, [executeRecaptcha, state.loading]);

    const handleVerifyPhoneNumberSuccess = async (verified) => {
        // const { businessAccount } = state;
        if (verified) {
            router.push('/dashboard');
        }
    };

    const resendCode = async () => {
        if (!state?.cus_unique_id || !cusUniqId) {
            snackbar.error('Something went wrong. Please refresh the page and retry again', 3000);
        }

        const { success, errorMessage } = await generateTwoFactorCodeV2({ cus_unique_id: state?.cus_unique_id || cusUniqId });
        if (success) {
            snackbar.success('code resend success', 3000);
            startTimer(60);
        } else if (errorMessage) {
            if (errorMessage.indexOf('Please wait for') > -1) {
                const timer = parseInt(errorMessage.slice(16, 19));
                if (!isNaN(timer) && timer <= 240) {
                    startTimer(timer);
                }
            }
            snackbar.error(errorMessage, 3000);
        }
    }

    const activeStepBySlug = () => {
        const { cus_2fa_preference, forgotPassword, isCusPhoneValid, isLegacyVerifyFlow, recoveryPasswordEmailSent } = state;
        let path = router.query ? router.query.step : '';
        if (cus_2fa_preference) {
            path = 'login-2fa';
        } else if (forgotPassword && !recoveryPasswordEmailSent) {
            path = 'forgot-password';
        } else if (!isCusPhoneValid && !isLegacyVerifyFlow) {
            path = 'phone-verification';
        } else if (recoveryPasswordEmailSent) {
            path = 'check-email';
        }
        if (path) {
            router.push(`/login?step=${path}`);
        }
        return steps.findIndex((s) => s.key === path);
    }

    const startTimer = (time) => {
        setEnableResendingTimer(time || 60);
        let value = time || 60;
        let resendCodeInterval = setInterval(() => {
            if (value > 0) {
                value = value - 1;
                setEnableResendingTimer(value);
            }
            else {
                window.clearInterval(resendCodeInterval);
            }

        }, 1000);
    }

    const getActiveStep = () => {
        switch (activeStep) {
            case 1:
                return (
                    <VerificationCodeScreen
                        phoneNumber={phoneNumber}
                        setState={setState}
                        state={state}
                        enableResendingTimer={enableResendingTimer}
                        startTimer={startTimer}
                        t={t}
                    />
                )
            case 2:
                return (
                    <PhoneNumberVerification
                        validationResultHandler={handleVerifyPhoneNumberSuccess}
                        onClickSendCode={resendCode}
                        signup={false}
                        isAllowedToEdit={true}
                        propsToBePassed={propsToBePassed}
                    />
                )
            case 4:
                return (
                    <ForgotPassword
                        setState={setState}
                        state={state}
                        isProd={isProd}
                        token={token}
                        propsToBePassed={propsToBePassed}
                        handleReCaptchaVerify={handleReCaptchaVerify}
                        setResetEmail={setResetEmail}
                        t={t}
                    />
                )
            case 6:
                return (
                    <CheckEmail
                        resetEmail={resetEmail}
                        t={t}
                    />
                )
            default:
                return (
                    <>
                        <Login
                            setState={setState}
                            state={state}
                            propsToBePassed={propsToBePassed}
                            handleSubmit={handleSubmit}
                            isProd={isProd}
                            token={token}
                            handleReCaptchaVerify={handleReCaptchaVerify}
                            setPhoneNumber={setPhoneNumber}
                            startTimer={startTimer}
                            t={t}
                        />
                    </>
                )
        }
    }

    return (
        <>
            {/* Render toolbar based on stepsToRender */}
            {!isMobile ?
                <Box display="flex">
                    <Box>
                        <Drawer />
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <div className={classes['rb-login-close-icon']}><span onClick={() => router.push('/')}><CloseIcon /></span></div>
                        <Spacing variant='containerHorizontalPadding'>
                            {getActiveStep()}
                        </Spacing>
                    </Box>
                </Box>
            :  <Box>
                    <TopBar onCloseAction={() => { router.push('/index') }} onBackAction={() => { router.push('/index') }} />
                    <Box>
                        <Spacing variant='containerHorizontalPadding'>
                            {getActiveStep()}
                        </Spacing>
                    </Box>
                </Box>
            }
        </>
    )



}

export default LoginWizard;