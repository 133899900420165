import React from 'react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LoginWizard from '../../src/components/login-v2/LoginWizard';

const Index = ({isMobileServer}) => {
    const { t } = useTranslation();

    return <LoginWizard t={t} isMobileServer={isMobileServer}/>
}

export async function getServerSideProps({ locale, req }) {
    const userAgent = req.headers['user-agent'];
    const isMobileServer = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
    return {
        props: {
            isMobileServer,
            ...(await serverSideTranslations(locale, ['common',  'login', 'login-signup-form'])),
        }
    }
}

export default Index;